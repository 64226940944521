import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4dfcc490 = () => interopDefault(import('../pages/aboutus.vue' /* webpackChunkName: "pages/aboutus" */))
const _0817917b = () => interopDefault(import('../pages/cipp-offer/index.vue' /* webpackChunkName: "pages/cipp-offer/index" */))
const _18aff078 = () => interopDefault(import('../pages/help.vue' /* webpackChunkName: "pages/help" */))
const _02e22b4b = () => interopDefault(import('../pages/verify-email.vue' /* webpackChunkName: "pages/verify-email" */))
const _ad69738c = () => interopDefault(import('../pages/business/analytics.vue' /* webpackChunkName: "pages/business/analytics" */))
const _44ac36ec = () => interopDefault(import('../pages/business/communication.vue' /* webpackChunkName: "pages/business/communication" */))
const _0817a129 = () => interopDefault(import('../pages/business/membersupport.vue' /* webpackChunkName: "pages/business/membersupport" */))
const _02a00e5e = () => interopDefault(import('../pages/dashboard/combine.vue' /* webpackChunkName: "pages/dashboard/combine" */))
const _4f04b34a = () => interopDefault(import('../pages/dashboard/documents.vue' /* webpackChunkName: "pages/dashboard/documents" */))
const _42d909a9 = () => interopDefault(import('../pages/dashboard/landing.vue' /* webpackChunkName: "pages/dashboard/landing" */))
const _428c7aa6 = () => interopDefault(import('../pages/dashboard/nextsteps.vue' /* webpackChunkName: "pages/dashboard/nextsteps" */))
const _e5f153dc = () => interopDefault(import('../pages/dashboard/report.vue' /* webpackChunkName: "pages/dashboard/report" */))
const _178f0d51 = () => interopDefault(import('../pages/dashboard/reports.vue' /* webpackChunkName: "pages/dashboard/reports" */))
const _61ea2357 = () => interopDefault(import('../pages/dashboard/retirement.vue' /* webpackChunkName: "pages/dashboard/retirement" */))
const _d9c5304a = () => interopDefault(import('../pages/dashboard/review-choices.vue' /* webpackChunkName: "pages/dashboard/review-choices" */))
const _7474037a = () => interopDefault(import('../pages/dashboard/selected-funds.vue' /* webpackChunkName: "pages/dashboard/selected-funds" */))
const _2fb4f712 = () => interopDefault(import('../pages/dashboard/selfhelpdirectory.vue' /* webpackChunkName: "pages/dashboard/selfhelpdirectory" */))
const _b93c97fe = () => interopDefault(import('../pages/dashboard/settings.vue' /* webpackChunkName: "pages/dashboard/settings" */))
const _24ea4d26 = () => interopDefault(import('../pages/dashboard/shortlist-funds.vue' /* webpackChunkName: "pages/dashboard/shortlist-funds" */))
const _80163ecc = () => interopDefault(import('../pages/onboarding/annuity-options.vue' /* webpackChunkName: "pages/onboarding/annuity-options" */))
const _3f82e288 = () => interopDefault(import('../pages/onboarding/authorization.vue' /* webpackChunkName: "pages/onboarding/authorization" */))
const _03e3da87 = () => interopDefault(import('../pages/onboarding/checkout.vue' /* webpackChunkName: "pages/onboarding/checkout" */))
const _4d4f0d76 = () => interopDefault(import('../pages/onboarding/confirmation.vue' /* webpackChunkName: "pages/onboarding/confirmation" */))
const _761ca984 = () => interopDefault(import('../pages/onboarding/employment-info.vue' /* webpackChunkName: "pages/onboarding/employment-info" */))
const _70861506 = () => interopDefault(import('../pages/onboarding/enhanced-quotes.vue' /* webpackChunkName: "pages/onboarding/enhanced-quotes" */))
const _7de2a005 = () => interopDefault(import('../pages/onboarding/fund-selection.vue' /* webpackChunkName: "pages/onboarding/fund-selection" */))
const _6770e078 = () => interopDefault(import('../pages/onboarding/login.vue' /* webpackChunkName: "pages/onboarding/login" */))
const _7b972e1a = () => interopDefault(import('../pages/onboarding/login-error.vue' /* webpackChunkName: "pages/onboarding/login-error" */))
const _5d09d179 = () => interopDefault(import('../pages/onboarding/medical-questionnaire/index.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/index" */))
const _7413ec78 = () => interopDefault(import('../pages/onboarding/money-purchase-annual-allowance.vue' /* webpackChunkName: "pages/onboarding/money-purchase-annual-allowance" */))
const _66f8ad4b = () => interopDefault(import('../pages/onboarding/my-tax-liability.vue' /* webpackChunkName: "pages/onboarding/my-tax-liability" */))
const _00f96b92 = () => interopDefault(import('../pages/onboarding/other-pot-addition.vue' /* webpackChunkName: "pages/onboarding/other-pot-addition" */))
const _12e1ae12 = () => interopDefault(import('../pages/onboarding/pension-details.vue' /* webpackChunkName: "pages/onboarding/pension-details" */))
const _4d371345 = () => interopDefault(import('../pages/onboarding/pension-provider-by-name.vue' /* webpackChunkName: "pages/onboarding/pension-provider-by-name" */))
const _62ab9509 = () => interopDefault(import('../pages/onboarding/pension-savings.vue' /* webpackChunkName: "pages/onboarding/pension-savings" */))
const _23d7a92a = () => interopDefault(import('../pages/onboarding/personal-info.vue' /* webpackChunkName: "pages/onboarding/personal-info" */))
const _18767304 = () => interopDefault(import('../pages/onboarding/redeem-voucher.vue' /* webpackChunkName: "pages/onboarding/redeem-voucher" */))
const _3d995b4c = () => interopDefault(import('../pages/onboarding/reset-password.vue' /* webpackChunkName: "pages/onboarding/reset-password" */))
const _18911b5c = () => interopDefault(import('../pages/onboarding/success.vue' /* webpackChunkName: "pages/onboarding/success" */))
const _4e5f6b9a = () => interopDefault(import('../pages/onboarding/suez-info.vue' /* webpackChunkName: "pages/onboarding/suez-info" */))
const _2d84abee = () => interopDefault(import('../pages/onboarding/tapered-allowance.vue' /* webpackChunkName: "pages/onboarding/tapered-allowance" */))
const _4ae71b67 = () => interopDefault(import('../pages/onboarding/tax-charge-period.vue' /* webpackChunkName: "pages/onboarding/tax-charge-period" */))
const _302c4998 = () => interopDefault(import('../pages/onboarding/tax-payment-methods.vue' /* webpackChunkName: "pages/onboarding/tax-payment-methods" */))
const _af8b723e = () => interopDefault(import('../pages/personal/account.vue' /* webpackChunkName: "pages/personal/account" */))
const _4896e248 = () => interopDefault(import('../pages/personal/nextsteps.vue' /* webpackChunkName: "pages/personal/nextsteps" */))
const _1d2caff5 = () => interopDefault(import('../pages/personal/retirement.vue' /* webpackChunkName: "pages/personal/retirement" */))
const _ac79c81a = () => interopDefault(import('../pages/onboarding/medical-questionnaire/ADL/index.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/ADL/index" */))
const _3f8c8542 = () => interopDefault(import('../pages/onboarding/medical-questionnaire/lifestyle/index.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/lifestyle/index" */))
const _138d95aa = () => interopDefault(import('../pages/onboarding/medical-questionnaire/medical-assessment/index.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/medical-assessment/index" */))
const _1d3b1cb6 = () => interopDefault(import('../pages/onboarding/medical-questionnaire/ADL/bathing-and-showering.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/ADL/bathing-and-showering" */))
const _fae3bb16 = () => interopDefault(import('../pages/onboarding/medical-questionnaire/ADL/bladder-control.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/ADL/bladder-control" */))
const _21847222 = () => interopDefault(import('../pages/onboarding/medical-questionnaire/ADL/bowel-control.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/ADL/bowel-control" */))
const _7f4bdd8c = () => interopDefault(import('../pages/onboarding/medical-questionnaire/ADL/dressing.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/ADL/dressing" */))
const _6f517165 = () => interopDefault(import('../pages/onboarding/medical-questionnaire/ADL/feeding.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/ADL/feeding" */))
const _0057f666 = () => interopDefault(import('../pages/onboarding/medical-questionnaire/ADL/mobility-indoors.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/ADL/mobility-indoors" */))
const _02ecabb5 = () => interopDefault(import('../pages/onboarding/medical-questionnaire/ADL/progression-over-time.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/ADL/progression-over-time" */))
const _189914f2 = () => interopDefault(import('../pages/onboarding/medical-questionnaire/ADL/review.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/ADL/review" */))
const _757fe7ec = () => interopDefault(import('../pages/onboarding/medical-questionnaire/ADL/transferring.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/ADL/transferring" */))
const _792db74a = () => interopDefault(import('../pages/onboarding/medical-questionnaire/lifestyle/alcohol.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/lifestyle/alcohol" */))
const _47fa8d96 = () => interopDefault(import('../pages/onboarding/medical-questionnaire/lifestyle/general.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/lifestyle/general" */))
const _e7ddfcca = () => interopDefault(import('../pages/onboarding/medical-questionnaire/lifestyle/review.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/lifestyle/review" */))
const _933ad68e = () => interopDefault(import('../pages/onboarding/medical-questionnaire/lifestyle/smoking.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/lifestyle/smoking" */))
const _b5b8d450 = () => interopDefault(import('../pages/onboarding/medical-questionnaire/medical-assessment/cancer.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/medical-assessment/cancer" */))
const _64ca4fdb = () => interopDefault(import('../pages/onboarding/medical-questionnaire/medical-assessment/diabetes.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/medical-assessment/diabetes" */))
const _ec7caa80 = () => interopDefault(import('../pages/onboarding/medical-questionnaire/medical-assessment/general.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/medical-assessment/general" */))
const _8ea78e8a = () => interopDefault(import('../pages/onboarding/medical-questionnaire/medical-assessment/high-cholesterol.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/medical-assessment/high-cholesterol" */))
const _45a6acbe = () => interopDefault(import('../pages/onboarding/medical-questionnaire/medical-assessment/hypertension.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/medical-assessment/hypertension" */))
const _0e0ad39c = () => interopDefault(import('../pages/onboarding/medical-questionnaire/medical-assessment/multiple-sclerosis.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/medical-assessment/multiple-sclerosis" */))
const _6a7d87a6 = () => interopDefault(import('../pages/onboarding/medical-questionnaire/medical-assessment/neurological.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/medical-assessment/neurological" */))
const _798f9aa0 = () => interopDefault(import('../pages/onboarding/medical-questionnaire/medical-assessment/review.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/medical-assessment/review" */))
const _ede1b5c0 = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _0682e15f = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aboutus",
    component: _4dfcc490,
    name: "aboutus"
  }, {
    path: "/cipp-offer",
    component: _0817917b,
    name: "cipp-offer"
  }, {
    path: "/help",
    component: _18aff078,
    name: "help"
  }, {
    path: "/verify-email",
    component: _02e22b4b,
    name: "verify-email"
  }, {
    path: "/business/analytics",
    component: _ad69738c,
    name: "business-analytics"
  }, {
    path: "/business/communication",
    component: _44ac36ec,
    name: "business-communication"
  }, {
    path: "/business/membersupport",
    component: _0817a129,
    name: "business-membersupport"
  }, {
    path: "/dashboard/combine",
    component: _02a00e5e,
    name: "dashboard-combine"
  }, {
    path: "/dashboard/documents",
    component: _4f04b34a,
    name: "dashboard-documents"
  }, {
    path: "/dashboard/landing",
    component: _42d909a9,
    name: "dashboard-landing"
  }, {
    path: "/dashboard/nextsteps",
    component: _428c7aa6,
    name: "dashboard-nextsteps"
  }, {
    path: "/dashboard/report",
    component: _e5f153dc,
    name: "dashboard-report"
  }, {
    path: "/dashboard/reports",
    component: _178f0d51,
    name: "dashboard-reports"
  }, {
    path: "/dashboard/retirement",
    component: _61ea2357,
    name: "dashboard-retirement"
  }, {
    path: "/dashboard/review-choices",
    component: _d9c5304a,
    name: "dashboard-review-choices"
  }, {
    path: "/dashboard/selected-funds",
    component: _7474037a,
    name: "dashboard-selected-funds"
  }, {
    path: "/dashboard/selfhelpdirectory",
    component: _2fb4f712,
    name: "dashboard-selfhelpdirectory"
  }, {
    path: "/dashboard/settings",
    component: _b93c97fe,
    name: "dashboard-settings"
  }, {
    path: "/dashboard/shortlist-funds",
    component: _24ea4d26,
    name: "dashboard-shortlist-funds"
  }, {
    path: "/onboarding/annuity-options",
    component: _80163ecc,
    name: "onboarding-annuity-options"
  }, {
    path: "/onboarding/authorization",
    component: _3f82e288,
    name: "onboarding-authorization"
  }, {
    path: "/onboarding/checkout",
    component: _03e3da87,
    name: "onboarding-checkout"
  }, {
    path: "/onboarding/confirmation",
    component: _4d4f0d76,
    name: "onboarding-confirmation"
  }, {
    path: "/onboarding/employment-info",
    component: _761ca984,
    name: "onboarding-employment-info"
  }, {
    path: "/onboarding/enhanced-quotes",
    component: _70861506,
    name: "onboarding-enhanced-quotes"
  }, {
    path: "/onboarding/fund-selection",
    component: _7de2a005,
    name: "onboarding-fund-selection"
  }, {
    path: "/onboarding/login",
    component: _6770e078,
    name: "onboarding-login"
  }, {
    path: "/onboarding/login-error",
    component: _7b972e1a,
    name: "onboarding-login-error"
  }, {
    path: "/onboarding/medical-questionnaire",
    component: _5d09d179,
    name: "onboarding-medical-questionnaire"
  }, {
    path: "/onboarding/money-purchase-annual-allowance",
    component: _7413ec78,
    name: "onboarding-money-purchase-annual-allowance"
  }, {
    path: "/onboarding/my-tax-liability",
    component: _66f8ad4b,
    name: "onboarding-my-tax-liability"
  }, {
    path: "/onboarding/other-pot-addition",
    component: _00f96b92,
    name: "onboarding-other-pot-addition"
  }, {
    path: "/onboarding/pension-details",
    component: _12e1ae12,
    name: "onboarding-pension-details"
  }, {
    path: "/onboarding/pension-provider-by-name",
    component: _4d371345,
    name: "onboarding-pension-provider-by-name"
  }, {
    path: "/onboarding/pension-savings",
    component: _62ab9509,
    name: "onboarding-pension-savings"
  }, {
    path: "/onboarding/personal-info",
    component: _23d7a92a,
    name: "onboarding-personal-info"
  }, {
    path: "/onboarding/redeem-voucher",
    component: _18767304,
    name: "onboarding-redeem-voucher"
  }, {
    path: "/onboarding/reset-password",
    component: _3d995b4c,
    name: "onboarding-reset-password"
  }, {
    path: "/onboarding/success",
    component: _18911b5c,
    name: "onboarding-success"
  }, {
    path: "/onboarding/suez-info",
    component: _4e5f6b9a,
    name: "onboarding-suez-info"
  }, {
    path: "/onboarding/tapered-allowance",
    component: _2d84abee,
    name: "onboarding-tapered-allowance"
  }, {
    path: "/onboarding/tax-charge-period",
    component: _4ae71b67,
    name: "onboarding-tax-charge-period"
  }, {
    path: "/onboarding/tax-payment-methods",
    component: _302c4998,
    name: "onboarding-tax-payment-methods"
  }, {
    path: "/personal/account",
    component: _af8b723e,
    name: "personal-account"
  }, {
    path: "/personal/nextsteps",
    component: _4896e248,
    name: "personal-nextsteps"
  }, {
    path: "/personal/retirement",
    component: _1d2caff5,
    name: "personal-retirement"
  }, {
    path: "/onboarding/medical-questionnaire/ADL",
    component: _ac79c81a,
    name: "onboarding-medical-questionnaire-ADL"
  }, {
    path: "/onboarding/medical-questionnaire/lifestyle",
    component: _3f8c8542,
    name: "onboarding-medical-questionnaire-lifestyle"
  }, {
    path: "/onboarding/medical-questionnaire/medical-assessment",
    component: _138d95aa,
    name: "onboarding-medical-questionnaire-medical-assessment"
  }, {
    path: "/onboarding/medical-questionnaire/ADL/bathing-and-showering",
    component: _1d3b1cb6,
    name: "onboarding-medical-questionnaire-ADL-bathing-and-showering"
  }, {
    path: "/onboarding/medical-questionnaire/ADL/bladder-control",
    component: _fae3bb16,
    name: "onboarding-medical-questionnaire-ADL-bladder-control"
  }, {
    path: "/onboarding/medical-questionnaire/ADL/bowel-control",
    component: _21847222,
    name: "onboarding-medical-questionnaire-ADL-bowel-control"
  }, {
    path: "/onboarding/medical-questionnaire/ADL/dressing",
    component: _7f4bdd8c,
    name: "onboarding-medical-questionnaire-ADL-dressing"
  }, {
    path: "/onboarding/medical-questionnaire/ADL/feeding",
    component: _6f517165,
    name: "onboarding-medical-questionnaire-ADL-feeding"
  }, {
    path: "/onboarding/medical-questionnaire/ADL/mobility-indoors",
    component: _0057f666,
    name: "onboarding-medical-questionnaire-ADL-mobility-indoors"
  }, {
    path: "/onboarding/medical-questionnaire/ADL/progression-over-time",
    component: _02ecabb5,
    name: "onboarding-medical-questionnaire-ADL-progression-over-time"
  }, {
    path: "/onboarding/medical-questionnaire/ADL/review",
    component: _189914f2,
    name: "onboarding-medical-questionnaire-ADL-review"
  }, {
    path: "/onboarding/medical-questionnaire/ADL/transferring",
    component: _757fe7ec,
    name: "onboarding-medical-questionnaire-ADL-transferring"
  }, {
    path: "/onboarding/medical-questionnaire/lifestyle/alcohol",
    component: _792db74a,
    name: "onboarding-medical-questionnaire-lifestyle-alcohol"
  }, {
    path: "/onboarding/medical-questionnaire/lifestyle/general",
    component: _47fa8d96,
    name: "onboarding-medical-questionnaire-lifestyle-general"
  }, {
    path: "/onboarding/medical-questionnaire/lifestyle/review",
    component: _e7ddfcca,
    name: "onboarding-medical-questionnaire-lifestyle-review"
  }, {
    path: "/onboarding/medical-questionnaire/lifestyle/smoking",
    component: _933ad68e,
    name: "onboarding-medical-questionnaire-lifestyle-smoking"
  }, {
    path: "/onboarding/medical-questionnaire/medical-assessment/cancer",
    component: _b5b8d450,
    name: "onboarding-medical-questionnaire-medical-assessment-cancer"
  }, {
    path: "/onboarding/medical-questionnaire/medical-assessment/diabetes",
    component: _64ca4fdb,
    name: "onboarding-medical-questionnaire-medical-assessment-diabetes"
  }, {
    path: "/onboarding/medical-questionnaire/medical-assessment/general",
    component: _ec7caa80,
    name: "onboarding-medical-questionnaire-medical-assessment-general"
  }, {
    path: "/onboarding/medical-questionnaire/medical-assessment/high-cholesterol",
    component: _8ea78e8a,
    name: "onboarding-medical-questionnaire-medical-assessment-high-cholesterol"
  }, {
    path: "/onboarding/medical-questionnaire/medical-assessment/hypertension",
    component: _45a6acbe,
    name: "onboarding-medical-questionnaire-medical-assessment-hypertension"
  }, {
    path: "/onboarding/medical-questionnaire/medical-assessment/multiple-sclerosis",
    component: _0e0ad39c,
    name: "onboarding-medical-questionnaire-medical-assessment-multiple-sclerosis"
  }, {
    path: "/onboarding/medical-questionnaire/medical-assessment/neurological",
    component: _6a7d87a6,
    name: "onboarding-medical-questionnaire-medical-assessment-neurological"
  }, {
    path: "/onboarding/medical-questionnaire/medical-assessment/review",
    component: _798f9aa0,
    name: "onboarding-medical-questionnaire-medical-assessment-review"
  }, {
    path: "/blog/:slug?",
    component: _ede1b5c0,
    name: "blog-slug"
  }, {
    path: "/",
    component: _0682e15f,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}

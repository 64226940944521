import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1c9d0699 = () => interopDefault(import('../pages/aboutus.vue' /* webpackChunkName: "pages/aboutus" */))
const _2d5d95ba = () => interopDefault(import('../pages/cipp-offer/index.vue' /* webpackChunkName: "pages/cipp-offer/index" */))
const _7a9b3283 = () => interopDefault(import('../pages/help.vue' /* webpackChunkName: "pages/help" */))
const _7fcbb1ec = () => interopDefault(import('../pages/verify-email.vue' /* webpackChunkName: "pages/verify-email" */))
const _d5bd928e = () => interopDefault(import('../pages/business/analytics.vue' /* webpackChunkName: "pages/business/analytics" */))
const _14057d89 = () => interopDefault(import('../pages/business/communication.vue' /* webpackChunkName: "pages/business/communication" */))
const _3e733a28 = () => interopDefault(import('../pages/business/membersupport.vue' /* webpackChunkName: "pages/business/membersupport" */))
const _fbab071c = () => interopDefault(import('../pages/dashboard/combine.vue' /* webpackChunkName: "pages/dashboard/combine" */))
const _44265aaa = () => interopDefault(import('../pages/dashboard/documents.vue' /* webpackChunkName: "pages/dashboard/documents" */))
const _7358e56c = () => interopDefault(import('../pages/dashboard/landing.vue' /* webpackChunkName: "pages/dashboard/landing" */))
const _5d16cbf2 = () => interopDefault(import('../pages/dashboard/nextsteps.vue' /* webpackChunkName: "pages/dashboard/nextsteps" */))
const _9b654b5e = () => interopDefault(import('../pages/dashboard/report.vue' /* webpackChunkName: "pages/dashboard/report" */))
const _c9ecde1c = () => interopDefault(import('../pages/dashboard/reports.vue' /* webpackChunkName: "pages/dashboard/reports" */))
const _9ff41fd4 = () => interopDefault(import('../pages/dashboard/retirement.vue' /* webpackChunkName: "pages/dashboard/retirement" */))
const _be1285cc = () => interopDefault(import('../pages/dashboard/review-choices.vue' /* webpackChunkName: "pages/dashboard/review-choices" */))
const _fb654e8e = () => interopDefault(import('../pages/dashboard/selected-funds.vue' /* webpackChunkName: "pages/dashboard/selected-funds" */))
const _6407b51a = () => interopDefault(import('../pages/dashboard/selfhelpdirectory.vue' /* webpackChunkName: "pages/dashboard/selfhelpdirectory" */))
const _e190b700 = () => interopDefault(import('../pages/dashboard/settings.vue' /* webpackChunkName: "pages/dashboard/settings" */))
const _ca47a7e4 = () => interopDefault(import('../pages/dashboard/shortlist-funds.vue' /* webpackChunkName: "pages/dashboard/shortlist-funds" */))
const _86643bce = () => interopDefault(import('../pages/onboarding/annuity-options.vue' /* webpackChunkName: "pages/onboarding/annuity-options" */))
const _4d5c37c7 = () => interopDefault(import('../pages/onboarding/authorization.vue' /* webpackChunkName: "pages/onboarding/authorization" */))
const _da680c30 = () => interopDefault(import('../pages/onboarding/checkout.vue' /* webpackChunkName: "pages/onboarding/checkout" */))
const _3b32d752 = () => interopDefault(import('../pages/onboarding/confirmation.vue' /* webpackChunkName: "pages/onboarding/confirmation" */))
const _7c6aa686 = () => interopDefault(import('../pages/onboarding/employment-info.vue' /* webpackChunkName: "pages/onboarding/employment-info" */))
const _76d41208 = () => interopDefault(import('../pages/onboarding/enhanced-quotes.vue' /* webpackChunkName: "pages/onboarding/enhanced-quotes" */))
const _2b33f2a6 = () => interopDefault(import('../pages/onboarding/fund-selection.vue' /* webpackChunkName: "pages/onboarding/fund-selection" */))
const _e6923692 = () => interopDefault(import('../pages/onboarding/login.vue' /* webpackChunkName: "pages/onboarding/login" */))
const _0edffc1c = () => interopDefault(import('../pages/onboarding/login-error.vue' /* webpackChunkName: "pages/onboarding/login-error" */))
const _9b2ce710 = () => interopDefault(import('../pages/onboarding/medical-questionnaire/index.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/index" */))
const _4d100ff7 = () => interopDefault(import('../pages/onboarding/money-purchase-annual-allowance.vue' /* webpackChunkName: "pages/onboarding/money-purchase-annual-allowance" */))
const _053fdbac = () => interopDefault(import('../pages/onboarding/my-tax-liability.vue' /* webpackChunkName: "pages/onboarding/my-tax-liability" */))
const _2a2e85b3 = () => interopDefault(import('../pages/onboarding/other-pot-addition.vue' /* webpackChunkName: "pages/onboarding/other-pot-addition" */))
const _0fbaaf91 = () => interopDefault(import('../pages/onboarding/pension-details.vue' /* webpackChunkName: "pages/onboarding/pension-details" */))
const _2e5e50a6 = () => interopDefault(import('../pages/onboarding/pension-provider-by-name.vue' /* webpackChunkName: "pages/onboarding/pension-provider-by-name" */))
const _5f849688 = () => interopDefault(import('../pages/onboarding/pension-savings.vue' /* webpackChunkName: "pages/onboarding/pension-savings" */))
const _31b0fe69 = () => interopDefault(import('../pages/onboarding/personal-info.vue' /* webpackChunkName: "pages/onboarding/personal-info" */))
const _bdd3cdc2 = () => interopDefault(import('../pages/onboarding/redeem-voucher.vue' /* webpackChunkName: "pages/onboarding/redeem-voucher" */))
const _e2f6b60a = () => interopDefault(import('../pages/onboarding/reset-password.vue' /* webpackChunkName: "pages/onboarding/reset-password" */))
const _40e53a5e = () => interopDefault(import('../pages/onboarding/success.vue' /* webpackChunkName: "pages/onboarding/success" */))
const _26ec16f2 = () => interopDefault(import('../pages/onboarding/suez-info.vue' /* webpackChunkName: "pages/onboarding/suez-info" */))
const _13dc47c8 = () => interopDefault(import('../pages/onboarding/tapered-allowance.vue' /* webpackChunkName: "pages/onboarding/tapered-allowance" */))
const _7585b926 = () => interopDefault(import('../pages/onboarding/tax-charge-period.vue' /* webpackChunkName: "pages/onboarding/tax-charge-period" */))
const _2d9a7397 = () => interopDefault(import('../pages/onboarding/tax-payment-methods.vue' /* webpackChunkName: "pages/onboarding/tax-payment-methods" */))
const _64ff69c0 = () => interopDefault(import('../pages/personal/account.vue' /* webpackChunkName: "pages/personal/account" */))
const _346cd2c7 = () => interopDefault(import('../pages/personal/nextsteps.vue' /* webpackChunkName: "pages/personal/nextsteps" */))
const _a7d66154 = () => interopDefault(import('../pages/personal/retirement.vue' /* webpackChunkName: "pages/personal/retirement" */))
const _76f1f4c4 = () => interopDefault(import('../pages/onboarding/medical-questionnaire/lifestyle/index.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/lifestyle/index" */))
const _6cf54e4c = () => interopDefault(import('../pages/onboarding/medical-questionnaire/lifestyle/alcohol.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/lifestyle/alcohol" */))
const _3bc22498 = () => interopDefault(import('../pages/onboarding/medical-questionnaire/lifestyle/general.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/lifestyle/general" */))
const _316cc13c = () => interopDefault(import('../pages/onboarding/medical-questionnaire/lifestyle/review.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/lifestyle/review" */))
const _87026d90 = () => interopDefault(import('../pages/onboarding/medical-questionnaire/lifestyle/smoking.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/lifestyle/smoking" */))
const _0b41049f = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _5df50e80 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aboutus",
    component: _1c9d0699,
    name: "aboutus"
  }, {
    path: "/cipp-offer",
    component: _2d5d95ba,
    name: "cipp-offer"
  }, {
    path: "/help",
    component: _7a9b3283,
    name: "help"
  }, {
    path: "/verify-email",
    component: _7fcbb1ec,
    name: "verify-email"
  }, {
    path: "/business/analytics",
    component: _d5bd928e,
    name: "business-analytics"
  }, {
    path: "/business/communication",
    component: _14057d89,
    name: "business-communication"
  }, {
    path: "/business/membersupport",
    component: _3e733a28,
    name: "business-membersupport"
  }, {
    path: "/dashboard/combine",
    component: _fbab071c,
    name: "dashboard-combine"
  }, {
    path: "/dashboard/documents",
    component: _44265aaa,
    name: "dashboard-documents"
  }, {
    path: "/dashboard/landing",
    component: _7358e56c,
    name: "dashboard-landing"
  }, {
    path: "/dashboard/nextsteps",
    component: _5d16cbf2,
    name: "dashboard-nextsteps"
  }, {
    path: "/dashboard/report",
    component: _9b654b5e,
    name: "dashboard-report"
  }, {
    path: "/dashboard/reports",
    component: _c9ecde1c,
    name: "dashboard-reports"
  }, {
    path: "/dashboard/retirement",
    component: _9ff41fd4,
    name: "dashboard-retirement"
  }, {
    path: "/dashboard/review-choices",
    component: _be1285cc,
    name: "dashboard-review-choices"
  }, {
    path: "/dashboard/selected-funds",
    component: _fb654e8e,
    name: "dashboard-selected-funds"
  }, {
    path: "/dashboard/selfhelpdirectory",
    component: _6407b51a,
    name: "dashboard-selfhelpdirectory"
  }, {
    path: "/dashboard/settings",
    component: _e190b700,
    name: "dashboard-settings"
  }, {
    path: "/dashboard/shortlist-funds",
    component: _ca47a7e4,
    name: "dashboard-shortlist-funds"
  }, {
    path: "/onboarding/annuity-options",
    component: _86643bce,
    name: "onboarding-annuity-options"
  }, {
    path: "/onboarding/authorization",
    component: _4d5c37c7,
    name: "onboarding-authorization"
  }, {
    path: "/onboarding/checkout",
    component: _da680c30,
    name: "onboarding-checkout"
  }, {
    path: "/onboarding/confirmation",
    component: _3b32d752,
    name: "onboarding-confirmation"
  }, {
    path: "/onboarding/employment-info",
    component: _7c6aa686,
    name: "onboarding-employment-info"
  }, {
    path: "/onboarding/enhanced-quotes",
    component: _76d41208,
    name: "onboarding-enhanced-quotes"
  }, {
    path: "/onboarding/fund-selection",
    component: _2b33f2a6,
    name: "onboarding-fund-selection"
  }, {
    path: "/onboarding/login",
    component: _e6923692,
    name: "onboarding-login"
  }, {
    path: "/onboarding/login-error",
    component: _0edffc1c,
    name: "onboarding-login-error"
  }, {
    path: "/onboarding/medical-questionnaire",
    component: _9b2ce710,
    name: "onboarding-medical-questionnaire"
  }, {
    path: "/onboarding/money-purchase-annual-allowance",
    component: _4d100ff7,
    name: "onboarding-money-purchase-annual-allowance"
  }, {
    path: "/onboarding/my-tax-liability",
    component: _053fdbac,
    name: "onboarding-my-tax-liability"
  }, {
    path: "/onboarding/other-pot-addition",
    component: _2a2e85b3,
    name: "onboarding-other-pot-addition"
  }, {
    path: "/onboarding/pension-details",
    component: _0fbaaf91,
    name: "onboarding-pension-details"
  }, {
    path: "/onboarding/pension-provider-by-name",
    component: _2e5e50a6,
    name: "onboarding-pension-provider-by-name"
  }, {
    path: "/onboarding/pension-savings",
    component: _5f849688,
    name: "onboarding-pension-savings"
  }, {
    path: "/onboarding/personal-info",
    component: _31b0fe69,
    name: "onboarding-personal-info"
  }, {
    path: "/onboarding/redeem-voucher",
    component: _bdd3cdc2,
    name: "onboarding-redeem-voucher"
  }, {
    path: "/onboarding/reset-password",
    component: _e2f6b60a,
    name: "onboarding-reset-password"
  }, {
    path: "/onboarding/success",
    component: _40e53a5e,
    name: "onboarding-success"
  }, {
    path: "/onboarding/suez-info",
    component: _26ec16f2,
    name: "onboarding-suez-info"
  }, {
    path: "/onboarding/tapered-allowance",
    component: _13dc47c8,
    name: "onboarding-tapered-allowance"
  }, {
    path: "/onboarding/tax-charge-period",
    component: _7585b926,
    name: "onboarding-tax-charge-period"
  }, {
    path: "/onboarding/tax-payment-methods",
    component: _2d9a7397,
    name: "onboarding-tax-payment-methods"
  }, {
    path: "/personal/account",
    component: _64ff69c0,
    name: "personal-account"
  }, {
    path: "/personal/nextsteps",
    component: _346cd2c7,
    name: "personal-nextsteps"
  }, {
    path: "/personal/retirement",
    component: _a7d66154,
    name: "personal-retirement"
  }, {
    path: "/onboarding/medical-questionnaire/lifestyle",
    component: _76f1f4c4,
    name: "onboarding-medical-questionnaire-lifestyle"
  }, {
    path: "/onboarding/medical-questionnaire/lifestyle/alcohol",
    component: _6cf54e4c,
    name: "onboarding-medical-questionnaire-lifestyle-alcohol"
  }, {
    path: "/onboarding/medical-questionnaire/lifestyle/general",
    component: _3bc22498,
    name: "onboarding-medical-questionnaire-lifestyle-general"
  }, {
    path: "/onboarding/medical-questionnaire/lifestyle/review",
    component: _316cc13c,
    name: "onboarding-medical-questionnaire-lifestyle-review"
  }, {
    path: "/onboarding/medical-questionnaire/lifestyle/smoking",
    component: _87026d90,
    name: "onboarding-medical-questionnaire-lifestyle-smoking"
  }, {
    path: "/blog/:slug?",
    component: _0b41049f,
    name: "blog-slug"
  }, {
    path: "/",
    component: _5df50e80,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
